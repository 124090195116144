export const WelcomeEmailContent = ({ name }) => {
  return (
    <div>
      <html>
        <body
          style={{
            fontFamily: "Arial, sans-serif",
            lineHeight: "1.6",
            color: "black",
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
            margin: "0",
          }}
        >
          <div style={{ width: "700px", margin: "auto", color: "black" }}>
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                color: "black",
              }}
            >
              <img
                src="https://myplaylist2023.s3.eu-north-1.amazonaws.com/logo3.png"
                height="70px"
                width="70px"
                alt="Company Logo"
                style={{
                  boxShadow: "0 0 3px rgba(0, 0, 0, 0.4)",
                }}
              />
              <h1
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  margin: "0",
                  color: "black",
                }}
              >
                INTERNATIONAL ACADEMY MBO
              </h1>
            </div>
            <hr
              style={{
                border: "none",
                borderBottom: "2px solid #ddd",
                marginBottom: "20px",
              }}
            />
            <h2
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginBottom: "20px",
                color: "black",
              }}
            >
              Шановний {name}, вітаємо зі вступом до
              <br /> INTERNATIONAL ACADEMY MBO.
            </h2>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Ми раді бачити вас серед наших студентів.
            </p>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Протягом наступних трьох днів ви матимете доступ до навчальних
              матеріалів, щоб ближче познайомитися з нашими програмами та знайти
              курси, які вас дійсно зацікавлять. Сподіваємось, цей час допоможе
              вам зробити впевнений вибір!
            </p>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Після оплати рахунку (Invoice) ви матимете п’ять років для
              вивчення обраних курсів. Цього часу цілком достатньо, щоб
              опанувати нові знання, скласти екзамени та подати заявку на
              стажування в одній з європейських компаній.
            </p>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Щодо вартості: завдяки вашій знижці у 50%, навчання обійдеться в
              600 євро замість звичайних 1200 євро. Якщо вам зручніше платити
              частинами, можна розбити оплату на 12 місяців (50 євро на місяць),
              заповнивши Форму 1 на розстрочку. Рахунок (Invoice) та Форму 1 ми
              вже відправили на вашу електронну пошту.
            </p>
            <p
              style={{
                marginBottom: "20px",
                color: "black",
                textAlign: "justify",
              }}
            >
              Бажаємо вам успішного навчання! Якщо будуть запитання – ми завжди
              на зв'язку.
            </p>
            <table style={{ width: "100%", marginTop: "30px" }}>
              <tbody>
                <tr>
                  <td style={{ width: "50%", color: "black" }}>
                    <p style={{ fontWeight: "bold", color: "black" }}>
                      З повагою,
                      <br />
                      Команда INTERNATIONAL ACADEMY MBO
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </body>
      </html>
    </div>
  );
};
