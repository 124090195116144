import { useState, useEffect } from "react";

// функція для визначення ширини вікна та положення прокрутки
export const useScrollPosition = () => {
  const [isWidthLessThan600, setIsWidthLessThan600] = useState(
    window.innerWidth <= 600
  );
  const [isScrollBelow500, setIsScrollBelow500] = useState(
    window.scrollY <= 150
  );

  useEffect(() => {
    const checkWindowWidth = () => {
      setIsWidthLessThan600(window.innerWidth <= 600);
    };
    const checkScrollPosition = () => {
      setIsScrollBelow500(
        window.innerWidth > 600 ? window.scrollY > 150 : false
      );
    };
    window.addEventListener("resize", checkWindowWidth);
    window.addEventListener("scroll", checkScrollPosition);

    // Перевірка при монтажі, щоб визначити початковий стан
    checkWindowWidth();
    checkScrollPosition();
    return () => {
      window.removeEventListener("resize", checkWindowWidth);
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);
  return isWidthLessThan600 || isScrollBelow500;
};

// функція для плавної прокрутки до елемента
export const ScrollToElement = (elem) => {
  const scrollPoint = document.getElementById(elem);
  const headerHeight = 60;

  if (scrollPoint) {
    const scrollPosition =
      scrollPoint.getBoundingClientRect().top + window.scrollY - headerHeight;

    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
    scrollPoint.classList.add("open");
  } else {
    console.error(`Element with ID ${elem} not found`);
  }
};

// фікс для смуги прокрутки.
// Якщо немає смуги прокрутки, то додаємо бордер, якщо є, то не додаємо
// і ця штука не знадобилась, бо я її не використовую

export const useAdjustBorders = (activeTab, isLoading) => {
  useEffect(() => {
    if (isLoading) return; // Чекаємо, поки завантаження завершиться

    const checkScrollbar = () => {
      return (
        document.documentElement.scrollHeight >
        document.documentElement.clientHeight
      );
    };

    const adjustBodyBorder = () => {
      const scrollbarExists = checkScrollbar();
      const bodyBorderStyle = scrollbarExists ? "none" : "7px solid #44545b";
      const headersBorderStyle = scrollbarExists ? "none" : "7px solid #009fe3";

      document.body.style.borderRight = bodyBorderStyle;

      const headersElement = document.querySelector(".headers");
      if (headersElement) {
        headersElement.style.borderRight = headersBorderStyle;
      }
    };

    // Використовуємо requestAnimationFrame для відкладеного виконання
    const rafId = requestAnimationFrame(() => {
      adjustBodyBorder();
    });

    const observer = new MutationObserver(() => {
      adjustBodyBorder();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    window.addEventListener("resize", adjustBodyBorder);

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", adjustBodyBorder);
      cancelAnimationFrame(rafId);
    };
  }, [activeTab, isLoading === false]); // Запускаємо, коли isLoading стає false
};
