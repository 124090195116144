// Формат дати в формат DD.MM.YYYY
// з нулем перед днями і місяцями
export const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0"); // Додає 0 перед днями < 10
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Місяці від 0 до 11, додаємо 1
  const year = date.getFullYear();
  return `${day}.${month}.${year}`; // Розділювач - крапка
};

// Другий спосіб формування дати
// const newDate = new Date()
// .toISOString()
// .split("T")[0]
// .split("-")
// .reverse()
// .join(".");
