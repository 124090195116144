import React from "react";
import { useTranslation } from "react-i18next";
import { DownloadFile } from "../../utils/fileHandlers/downloadFile";
import "./footerStyle.scss";

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <main className="footer" id="contacts">
      <section className="footer__col">
        <p className="footer__title">INTERNATIONAL ACADEMY MBO</p>

        <div className="footer__row">
          <img
            className="footer__icon  footer__icon--invert"
            src="https://w7.pngwing.com/pngs/931/760/png-transparent-building-computer-icons-office-building-angle-building-rectangle-thumbnail.png"
            alt="address-icon"
          />
          <p className="footer__text">{t("address_mbo")}</p>
        </div>
      </section>

      <section className="footer__col">
        <p className="footer__title">{t("contacts")}</p>
        <div className="footer__row">
          <img
            className="footer__icon footer__icon--invert"
            src="https://cdn-icons-png.flaticon.com/512/561/561127.png"
            alt="email-icon"
          />
          {/* <p className="footer__text">inacademyMBO@gmail.com</p> */}

          <a className="footer__link" href="mailto:inacademyMBO.post@gmail.com">
            inacademyMBO.post@gmail.com
          </a>
        </div>
      </section>

      <section className="footer__col">
        <p className="footer__title">{t("documents")}</p>
        <div className="footer__text footer__text--btn row">
          <DownloadFile />
        </div>
        {/* <p className="footer__text">{t("extract_from_kvk")}</p> */}
      </section>
      <SocialsComponent />

      <div className="footer__divider"></div>

      <p className="footer__copy">
        © {currentYear}. {"  "} INTERNATIONAL ACADEMY MBO. KVK-nummer 88463192.
        {"  "}
        {t("all_rights_reserved")}.
      </p>
    </main>
  );
};

const SocialsComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="footer__col">
        <p className="footer__title">{t("social_media")}</p>
        <div className="footer__linkwrap">
          {/* <a href="https://www.facebook.com/groups/390578963845505">
            <img
              className="footer__icon"
              src="https://cdn-icons-png.flaticon.com/512/174/174848.png"
              alt="img-facebook"
            />
          </a> */}
          <a href="https://t.me/+Hy8uWITdllxhZWQ6">
            <img
              className="footer__icon"
              src="https://cdn-icons-png.flaticon.com/512/2111/2111646.png"
              alt="img-telegram"
            />
          </a>
        </div>
      </div>
    </>
  );
};
