import {
  collection,
  query,
  where,
  setDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

import {
  deleteUser,
  updateEmail,
  updateProfile,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";
import defaultPhoto from "../../lib/noname-ava2.png";

export const SendUserDataToBackend = async (userData, firestore, t) => {
  const usersCollection = collection(firestore, "users");
  try {
    await addDoc(usersCollection, userData);
    console.log("User data has been successfully sent to the backend.");
  } catch (error) {
    console.error("Error sending user data to the backend: ", error);
    toast.error(t("data_sending_error"));
  }
};

export const CreateNewUser = async (
  firestore,
  phoneNumber,
  displayName,
  email,
  photoURL
) => {
  // const { t } = useTranslation();

  try {
    const usersCollection = collection(firestore, "users");
    const q = query(usersCollection, where("phoneNumber", "==", phoneNumber));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      console.log("Adding new user to database");

      await addDoc(usersCollection, {
        displayName:
          displayName || phoneNumber || `user-${new Date().toISOString()}`,
        phoneNumber: phoneNumber || null,
        email: email || null,
        address: null,
        photoURL: photoURL || defaultPhoto,
        regDate: new Date().toISOString(), // Змінено на ISO string для кращої сумісності
      });
      console.log("Додано нового користувача");
      // toast.success(t("new_user_added"));
      toast.success("New user added");
    } else {
      console.log(`Користувач з таким номером ${phoneNumber} вже існує`);
      // toast.error(t("user_already_exists"));
      toast.success("User already exists");
    }
  } catch (error) {
    console.error("Помилка додавання нового користувача: ", error);
    // toast.error(t("add_user_error"));
    toast.error("Error adding new user");
  }
};

export const UpdateCurrentUser = async (auth, updateProperties) => {
  try {
    if (auth.currentUser) {
      // Оновлення профілю з використанням спред оператора
      if (updateProperties.displayName || updateProperties.photoURL) {
        await updateProfile(auth.currentUser, {
          ...updateProperties, // Оновлює лише вказані властивості
        });
        console.log("Профіль оновлено");
        // toast.success(t("profile_updated"));
        toast.success("Profile updated");
      }

      // Оновлення email, якщо вказано і він відрізняється від поточного
      if (
        updateProperties.email &&
        updateProperties.email !== auth.currentUser.email
      ) {
        await updateEmail(auth.currentUser, updateProperties.email);
        console.log("Електронну пошта оновлено.");
        toast.success("Email updated");
      }
    } else {
      console.log("Поточний користувач не знайдено.");
      // toast.error(t("current_user_not_found"));
      toast.error("Current user not found");
    }
  } catch (error) {
    console.error("Помилка оновлення поточного користувача: ", error);
    // toast.error(t("update_user_error"));
    toast.error("Error updating user");
  }
};

export const updateCurrentUserProfile = async (
  auth,
  user,
  firstName,
  lastName,
  photoURL
) => {
  try {
    await updateProfile(user, {
      displayName: firstName + " " + lastName,
      photoURL: photoURL || defaultPhoto,
    });
    console.log("profile_updated");
  } catch (error) {
    console.error("Error updating profile: ", error);
  }
};

export const UpdateUserCollection = async (
  firestore,
  email,
  updateProperties
) => {
  // const { t } = useTranslation();

  try {
    const usersCollection = collection(firestore, "users");
    const q = query(usersCollection, where("email", "==", email));
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      const userIdToUpdate = snapshot.docs[0].id;
      const userDocRef = doc(usersCollection, userIdToUpdate);

      // Фільтрування властивостей, щоб видалити ті, що мають значення undefined
      const filteredProperties = Object.entries(updateProperties).reduce(
        (acc, [key, value]) => {
          if (value !== undefined) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      await updateDoc(userDocRef, filteredProperties);

      console.log("Оновлено дані користувача");
      // toast.success(t("profile_updated"));
      toast.success("Profile updated");
    } else {
      console.log("Користувач не знайдено");
      // toast.error(t("user_not_found"));
      toast.error("User not found");
    }
  } catch (error) {
    console.error("Помилка оновлення користувача: ", error);
    toast.error("Error updating user");
  }
};

export const createUserProgress = async (userData, firestore) => {
  let lessonsProgress = {};
  let coursesData = {};

  // Перевірка чи існує selectedCourse в userData
  // if (userData.selectedCourse) {
  //   const coursesQuery = query(
  //     collection(firestore, "template-lessons"),
  //     where("name", "==", userData.selectedCourse)
  //   );
  //   const querySnapshot = await getDocs(coursesQuery);
  //   if (querySnapshot.empty) {
  //     console.log("No such course!");
  //   } else {
  //     querySnapshot.forEach((doc) => {
  //       const courseData = doc.data();
  //       courseData.lessons.forEach((lesson) => {
  //         lessonsProgress[lesson] = false;
  //       });
  //     });
  //     coursesData = {
  //       [userData.selectedCourse]: lessonsProgress,
  //     };
  //   }
  // } else {
  //   console.log("Selected course is not provided");
  // }

  // Зберігаємо прогрес студента в Firestore з використанням UID як ідентифікатора
  const studentProgressRef = doc(firestore, `student-progress/${userData.uid}`);
  await setDoc(
    studentProgressRef,
    {
      studentUid: userData.uid,
      studentName: userData.displayName,
      courses: coursesData,
    },
    { merge: true }
  );

  console.log("Student progress created successfully");
};

export const createSurveyData = async (surveyData, firestore) => {
  const surveyDataRef = doc(firestore, `questionare/${surveyData.studentUid}`);
  try {
    await setDoc(
      surveyDataRef,
      {
        ...surveyData,
      }
      // { merge: true } // Закоментовано = перезапис
    );

    console.log("Survey data created successfully");
  } catch (error) {
    console.error("Error creating survey data: ", error);
  }
};

export const DeleteUserCollection = async (firestore, email, auth, uid) => {
  try {
    // Видалення користувача з колекції users
    const usersCollection = collection(firestore, "users");
    const userQuery = query(usersCollection, where("email", "==", email));
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
      const userIdToDelete = userSnapshot.docs[0].id;
      const userDocRef = doc(usersCollection, userIdToDelete);
      await deleteDoc(userDocRef);
      console.log("Користувача видалено з колекції users");
      toast.success("User deleted from users collection");
    } else {
      console.log("Користувача з такою електронною поштою не знайдено");
      toast.error("User with this email not found");
    }

    // Видалення інформації користувача з колекції student-progress
    const studentProgressCollection = collection(firestore, "student-progress");
    const studentProgressQuery = query(
      studentProgressCollection,
      where("studentUid", "==", uid)
    );
    const studentProgressSnapshot = await getDocs(studentProgressQuery);

    if (!studentProgressSnapshot.empty) {
      const studentProgressDocId = studentProgressSnapshot.docs[0].id;
      const studentProgressDocRef = doc(
        studentProgressCollection,
        studentProgressDocId
      );
      await deleteDoc(studentProgressDocRef);
      console.log(
        "Інформацію про користувача видалено з колекції student-progress"
      );
      toast.success(
        "User information deleted from student-progress collection"
      );
    } else {
      console.log("Інформацію про користувача з таким studentUid не знайдено");
      toast.error("User information with this studentUid not found");
    }

    // Видалення користувача з auth
    const user = auth.currentUser;
    if (user) {
      await deleteUser(user);
      console.log("Користувача видалено з auth");
      toast.success("User deleted from auth");
    } else {
      console.log("Поточний користувач не знайдено для видалення з auth");
      toast.error("Current user not found for deletion from auth");
    }
  } catch (error) {
    console.error("Помилка видалення користувача: ", error);
    toast.error("Error deleting user");
  }
};

export const DeleteUserCollection2 = async (auth, firestore, email, uid, t) => {
  try {
    const user = auth.currentUser;
    if (user) {
      // Реавтентифікація користувача перед видаленням
      const credential = EmailAuthProvider.credential(
        user.email,
        prompt(t("delete_account_confirmation"))
      );
      await reauthenticateWithCredential(user, credential);

      // Видалення користувача з Auth
      await deleteUser(user);

      // Видалення колекцій, пов'язаних з користувачем
      // await deleteDoc(doc(firestore, "users", uid)); // Використовуємо doc і deleteDoc
      // await deleteDoc(doc(firestore, "student-progress", uid)); // Використовуємо doc і deleteDoc

      toast.success(t("your_profile_has_been_deleted"));
    } else {
      toast.error(t("user_not_found"));
    }
  } catch (error) {
    console.error(`Error deleting user:`, error);
    toast.error(t("deletion_cancelled"));
  }
};

export const handleAvatarChange = async (
  e,
  user,
  firestore,
  storage,
  auth,
  t,
  setUserData
) => {
  const file = e.target.files[0];
  if (!file) return;
  if (
    file.size > 5 * 1024 * 1024 ||
    !(file.type === "image/jpeg" || file.type === "image/png")
  ) {
    toast.error(t("image_size_limit"));
    return;
  }
  const avatarRef = ref(storage, `avatars/${user.uid}`); // Використання UID замість email
  const uploadTask = uploadBytesResumable(avatarRef, file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {},
    (error) => {
      console.error("Upload failed", error);
      toast.error(t("upload_failed"));
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
        console.log("File available at", downloadURL);
        const updateProperties = { photoURL: downloadURL };
        try {
          await UpdateUserCollection(firestore, user.email, updateProperties);
          await UpdateCurrentUser(auth, updateProperties);
          setUserData((prev) => ({ ...prev, photoURL: downloadURL }));
          toast.success("Аватар успішно оновлено");
        } catch (error) {
          console.error("Помилка оновлення інформації про аватар", error);
          toast.error(t("avatar_update_error"));
        }
      });
    }
  );
};
