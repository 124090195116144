import { doc, getDoc, updateDoc } from "firebase/firestore";

export const generateInvoiceNumber = async (firestore) => {
  const stateDocRef = doc(firestore, "project-state", "ADreipLmXLlVH0oLEEre"); // ID документа
  const stateDoc = await getDoc(stateDocRef);

  let invoiceNumber;
  if (stateDoc.exists()) {
    const lastInvoice = stateDoc.data()["last-invoice"];

    // Збільшуємо номер на 1
    const newNumber = (parseInt(lastInvoice, 10) + 1)
      .toString()
      .padStart(6, "0");
    invoiceNumber = newNumber;

    // Оновлюємо документ з новим номером інвойсу
    await updateDoc(stateDocRef, { "last-invoice": invoiceNumber });
  } else {
    // Якщо документ не існує, починаємо з першого інвойсу
    invoiceNumber = "000001";
    await updateDoc(stateDocRef, { "last-invoice": invoiceNumber });
  }

  return invoiceNumber;
};
