import ReactDOMServer from "react-dom/server";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";

import { WelcomeEmailContent } from "../textHandlers/WelcomeEmailContent";
import { generateDocument } from "../fileHandlers/docxTemplater";

export const SendRegistrationEmail = async (userData, firestore, storage) => {
  const mailCollection = collection(firestore, "mail");
  const emailAddresses =
    userData.relativeEmail && userData.relativeEmail !== "Не вказано"
      ? [userData.email, userData.relativeEmail]
      : [userData.email];

  const name = userData.displayName || "Студент";
  const emailHtml = ReactDOMServer.renderToStaticMarkup(
    <WelcomeEmailContent name={name} />
  );

  const emailText = `
  Шановний ${name}, вітаємо зі вступом до INTERNATIONAL ACADEMY MBO.
  
  Ми раді бачити вас серед наших студентів. 
  Протягом наступних трьох днів ви матимете доступ до навчальних матеріалів, щоб ближче познайомитися з нашими програмами та знайти курси, які вас дійсно зацікавлять. Сподіваємось, цей час допоможе вам зробити впевнений вибір!
  Після оплати рахунку (Invoice) ви матимете п’ять років для вивчення обраних курсів. Цього часу цілком достатньо, щоб опанувати нові знання, скласти екзамени та подати заявку на стажування в одній з європейських компаній.
  Щодо вартості: завдяки вашій знижці у 50%, навчання обійдеться в 600 євро замість звичайних 1200 євро. Якщо вам зручніше платити частинами, можна розбити оплату на 12 місяців (50 євро на місяць), заповнивши Форму 1 на розстрочку. Рахунок (Invoice) та Форму 1 ми вже відправили на вашу електронну пошту.
  Бажаємо вам успішного навчання! Якщо будуть запитання – ми завжди на зв'язку.
  
  З повагою, 
  Команда INTERNATIONAL ACADEMY MBO
  `;

  // ГЕНЕРУЄМО ЛИСТА
  generateDocument(userData, "invoice", firestore, async (blob) => {
    // формуємо датку в форматі dd.mm.yyyy
    const newDate = new Date();
    const day = String(newDate.getDate()).padStart(2, "0"); // Отримуємо день з нулем попереду
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Отримуємо місяць (додаємо 1, оскільки місяці починаються з 0)
    const year = newDate.getFullYear(); // Отримуємо рік
    const formattedDate = `${day}.${month}.${year}`;

    const storageRef = ref(
      storage,
      `invoices/${userData.displayName}-${formattedDate}.docx`
    );
    try {
      await uploadBytes(storageRef, blob);
      const downloadURL = await getDownloadURL(storageRef);
      const emailData = {
        to: emailAddresses,
        message: {
          subject: "INTERNATIONAL ACADEMY MBO",
          text: emailText,
          html: emailHtml,
          replyTo: "inacademymbo.post@gmail.com",
          attachments: [
            {
              filename: "invoice.docx",
              path: downloadURL,
            },
          ],
        },
      };
      await addDoc(mailCollection, emailData);
      console.log("Registration email has been added to the collection.");
      toast.success("Check your email");
    } catch (error) {
      console.error("Error adding registration email: ", error);
      toast.error("Error adding registration email.");
    }
  });
};
